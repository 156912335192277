import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Hellheim from '../src/components/Hellheim/Hellheim';
import Heimdall from '../src/components/Heimdall/Heimdall';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import Hod from '../src/components/Hod/Hod';
import Layout from '../src/components/Layout/Layout';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import SEO from '../src/helpers/seo';

import './trabajar_nosotros.scss';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query trabaja_con_nosotros($locale: String!) {
    allTrabajaSeoBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allTrabajaTitle(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allTrabajaTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allTrabajaImageBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allTrabajaImageMobileBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allTrabajaBreadcrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allTrabajaContentBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          body
          title
        }
      }
    }
    allTrabajaPreguntasFrecuentes(
      filter: { lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          question
          answer
        }
      }
    }
    allTrabajaPreguntasTitle(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
        }
      }
    }
    allTrabajaRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
            imgRich
            price
            date_valid_until
            date_valid_start
          }
        }
      }
    }
  }
`;

class TrabajaNosotros extends React.Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allTrabajaTitle.edges[0].node.title,
      subtitle: this.props.data.allTrabajaTitle.edges[0].node.description,
      image: {
        url: this.props.data.allTrabajaImageBlock.edges[0].node.localImage.childImageSharp.fluid,
        alt: this.props.data.allTrabajaImageBlock.edges[0].node.alt,
      },
      imageMobile: {
        url: this.props.data.allTrabajaImageMobileBlock.edges[0].node.localImage.childImageSharp
          .fluid,
        alt: this.props.data.allTrabajaImageMobileBlock.edges[0].node.alt,
      },
    };

    const contentData = {
      title: this.props.data.allTrabajaContentBlock.edges[0].node.title,
      body: this.props.data.allTrabajaContentBlock.edges[0].node.body,
    };

    const hellheimData = {
      title: this.props.data.allTrabajaPreguntasTitle.edges[0].node.title,
      text: '',
      menus: this.props.data.allTrabajaPreguntasFrecuentes.edges.map((item, index) => ({
        title: item.node.question,
        text: item.node.answer,
      })),
    };

    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale} pageName={this.props.pageContext.pageName}>
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <SEO
            title={this.props.data.allTrabajaSeoBlock.edges[0].node._0.title}
            description={this.props.data.allTrabajaSeoBlock.edges[0].node._1.description}
            pathname={this.props.pageContext.url}
            lang={this.props.pageContext.locale}
            imageRich={this.props.data.allTrabajaRichData.edges[0].node.rich_markup.imgRich}
          />
          <Hod
            data={this.props.data.allTrabajaRichData.edges[0].node.rich_markup}
            url={this.props.pageContext.url}
            img={this.props.data.allTrabajaRichData.edges[0].node.rich_markup.imgRich}
          />
          <div className="general-index">
            <div className="trabajar-nosotros-container">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allTrabajaBreadcrumbBlock.edges[0].node.name}
              />
              <H3 data={contentData} />
              <div
                className="text-container"
                dangerouslySetInnerHTML={{ __html: contentData.body }}
              />
              <Hellheim data={hellheimData} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default TrabajaNosotros;
